@font-face {
	font-family: "icomoon";
	src: url("fonts/icomoon.eot?ehy8ce");
	src: url("fonts/icomoon.eot?ehy8ce#iefix") format("embedded-opentype"),
		url("fonts/icomoon.woff2?ehy8ce") format("woff2"),
		url("fonts/icomoon.ttf?ehy8ce") format("truetype"),
		url("fonts/icomoon.woff?ehy8ce") format("woff"),
		url("fonts/icomoon.svg?ehy8ce#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "icomoon" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-wifi:before {
	content: "\e940";
}
.icon-sale:before {
	content: "\e941";
}
.icon-pipeline-report:before {
	content: "\e93c";
}
.icon-conversion-report:before {
	content: "\e93d";
}
.icon-operations-report:before {
	content: "\e93e";
}
.icon-listing-report:before {
	content: "\e93f";
}
.icon-download:before {
	content: "\e938";
	color: #a7a5a5;
}
.icon-email:before {
	content: "\e939";
	color: #a7a5a5;
}
.icon-date:before {
	content: "\e93a";
	color: #7e9190;
}
.icon-report:before {
	content: "\e93b";
	color: #618583;
}
.icon-forward:before {
	content: "\e936";
}
.icon-reply:before {
	content: "\e937";
}
.icon-arrow-bottom:before {
	content: "\e935";
}
.icon-icons8-rating .path1:before {
	content: "\e92d";
	color: rgb(255, 255, 255);
}
.icon-icons8-rating .path2:before {
	content: "\e92e";
	margin-left: -1.150390625em;
	color: rgb(242, 182, 48);
}
.icon-icons8-rating .path3:before {
	content: "\e92f";
	margin-left: -1.150390625em;
	color: rgb(68, 75, 84);
}
.icon-icons8-rating .path4:before {
	content: "\e930";
	margin-left: -1.150390625em;
	color: rgb(68, 75, 84);
}
.icon-area:before {
	content: "\e931";
	color: #3fa6a2;
}
.icon-bath:before {
	content: "\e932";
	color: #3fa6a2;
}
.icon-bedroom:before {
	content: "\e933";
	color: #3fa6a2;
}
.icon-fence:before {
	content: "\e934";
	color: #3fa6a2;
}
.icon-check:before {
	content: "\e92b";
}
.icon-dot:before {
	content: "\e92c";
}
.icon-status-active-gray .path1:before {
	content: "\e922";
	color: rgb(86, 90, 102);
}
.icon-status-active-gray .path2:before {
	content: "\e923";
	margin-left: -1.5em;
	color: rgb(101, 109, 135);
}
.icon-status-active-orange:before {
	content: "\e924";
	color: #eb9d09;
}
.icon-status-new-gray:before {
	content: "\e925";
	color: #565a66;
}
.icon-status-new-orange:before {
	content: "\e926";
	color: #eb9d09;
}
.icon-status-offmarket-gray:before {
	content: "\e927";
	color: #565a66;
}
.icon-status-offmarket-orange:before {
	content: "\e928";
	color: #eb9d09;
}
.icon-status-pending-gray:before {
	content: "\e929";
	color: #565a66;
}
.icon-status-pending-orange:before {
	content: "\e92a";
	color: #eb9d09;
}
.icon-upload:before {
	content: "\e921";
}
.icon-filter:before {
	content: "\e920";
	color: #fff;
}
.icon-archive:before {
	content: "\e91c";
	color: #868686;
}
.icon-inprocess .path1:before {
	content: "\e91d";
	color: rgb(122, 141, 140);
}
.icon-inprocess .path2:before {
	content: "\e91e";
	margin-left: -1em;
	color: rgb(33, 206, 129);
}
.icon-inprocess .path3:before {
	content: "\e91f";
	margin-left: -1em;
	color: rgb(250, 250, 250);
}
.icon-valentines-heart-1:before {
	content: "\e91b";
	color: #de1832;
}
.icon-building:before {
	content: "\e917";
}
.icon-property:before {
	content: "\e91a";
}
.icon-chats:before {
	content: "\e913";
}
.icon-check-filled:before {
	content: "\e914";
}
.icon-inbox:before {
	content: "\e915";
}
.icon-mail:before {
	content: "\e916";
}
.icon-sms:before {
	content: "\e918";
}
.icon-wallet:before {
	content: "\e919";
}
.icon-info:before {
	content: "\e912";
}
.icon-logout:before {
	content: "\e911";
}
.icon-badge-check:before {
	content: "\e90e";
}
.icon-location:before {
	content: "\e90f";
}
.icon-phone:before {
	content: "\e910";
}
.icon-bell:before {
	content: "\e90d";
}
.icon-edit:before {
	content: "\e909";
}
.icon-search:before {
	content: "\e90a";
}
.icon-template:before {
	content: "\e90b";
}
.icon-trash:before {
	content: "\e90c";
}
.icon-dashboard:before {
	content: "\e900";
}
.icon-graph-up:before {
	content: "\e901";
}
.icon-hide:before {
	content: "\e902";
}
.icon-home:before {
	content: "\e903";
}
.icon-price-tag:before {
	content: "\e904";
}
.icon-settings:before {
	content: "\e905";
}
.icon-show:before {
	content: "\e906";
}
.icon-user:before {
	content: "\e907";
}
.icon-users-check:before {
	content: "\e908";
}
