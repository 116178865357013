.super-admin-list {
	.card-primary {
		&.add-new {
			position: relative;
			min-height: 150px;
			transition: all 0.2s ease-in-out;
			&:hover {
				transform: scale(1.1, 1.1);
			}
			&:before {
				content: "";
				display: block;
				border: 1px dashed var(--color-primary);
				position: absolute;
				width: calc(100% - 30px);
				height: calc(100% - 30px);
				left: 15px;
				top: 15px;
				pointer-events: none;
				border-radius: 4px;
			}
		}
	}
}
@keyframes user-settings-form-anim {
	from {
		transform: scale(0.98, 0.98);
		opacity: 0;
	}
	to {
		transform: scale(1, 1);
		opacity: 1;
	}
}
.user-settings-form {
	animation: user-settings-form-anim 0.4s ease-in-out;
	.form-footer {
		text-align: right;
	}
}

.settings-datasource-group {
	display: flex;
	flex-direction: column-reverse;
	position: relative;
}
.settings-datasource-style {
	border: 1px solid var(--color-border-light);
	border-radius: 18px;
	padding: 0 15px;
	font-size: 14px;
	min-height: 38px;
	outline: none;
	box-shadow: none;
	font-weight: 500;
	text-align: center;
	background-color: #f7f7f7;
	
}
.settings-datasource-form-label {
	padding: 5px 0;
	font-size: 12px;
	color: var(--color-labels);
}
.w-15 {
	width: 15%;
}
.result-block {
	border: 2px dotted #c0d4d3;
	border-radius: 6px;
	height: 56px;
	background-color: #e0efee;
	width: 35%;
	padding-top: 15px !important;
}
.notification-icon{
	margin-top: 1.8rem;
}
